import React, { Component, ComponentState } from "react";
import { appInsights } from "../../components/AppInsights/AppInsights";
import LocalizedLink from "../../components/LocalizedLink";
import TagManager from "react-gtm-module";
import axios from "axios";

import { IPageContext, IPrismicContactUs } from "../prismic.model";
import { validateEmail } from "../../utils/constants";

import * as styles from "./ContactUs.module.scss";

interface IProps {
  data: IPrismicContactUs;
  pageContext: IPageContext;
}

interface IState {
  isMailSent: boolean;
  mailNotSent: boolean;
  name: string;
  companyName: string;
  email: string;
  message: string;
  nameError: boolean;
  companyNameError: boolean;
  emailError: boolean;
  messageError: boolean;
}

export default class ContactUs extends Component<IProps, IState> {
  state = {
    isMailSent: false,
    mailNotSent: false,
    name: "",
    companyName: "",
    email: "",
    message: "",
    nameError: false,
    companyNameError: false,
    emailError: false,
    messageError: false,
  };

  componentDidMount() {
    appInsights.trackPageView();
  }

  componentWillUnmount() {
    this.setState({
      isMailSent: false,
      mailNotSent: false,
    });
  }

  render() {
    const { data } = this.props;
    const {
      isMailSent,
      name,
      companyName,
      email,
      message,
      mailNotSent,
      nameError,
      companyNameError,
      emailError,
      messageError,
    } = this.state;

    return (
      <div className={styles.container}>
        {isMailSent || mailNotSent ? (
          <div className={styles.card}>
            <h2>
              {mailNotSent ? data.error_title.text : data.success_title.text}
            </h2>
            <div className={styles.description}>
              {mailNotSent
                ? data.error_description.text
                : data.success_description.text}
            </div>

            {mailNotSent ? (
              <button className={styles.button} onClick={this.tryAgain}>
                {data.error_button.text}
              </button>
            ) : (
              <button className={styles.button}>
                <LocalizedLink to="">{data.success_button.text}</LocalizedLink>
              </button>
            )}
          </div>
        ) : (
          <div className={styles.card}>
            <h2>{data.title.text}</h2>
            <div className={styles.description}>{data.description.text}</div>

            <form className={styles.form} onSubmit={this.onSubmit}>
              <div className={styles.inputContainer}>
                <div className={styles.label}>{data.label_name.text}*</div>
                <input
                  type="text"
                  className={`${styles.input} ${nameError && styles.error}`}
                  name="name"
                  value={name}
                  onChange={this.onInputChange}
                />
                {nameError && (
                  <div className={styles.labelError}>
                    {data.label_error.text}
                  </div>
                )}
              </div>

              <div
                className={`${styles.inputContainer} ${
                  emailError && styles.error
                }`}
              >
                <div className={styles.label}>{data.label_company.text}*</div>
                <input
                  type="text"
                  className={`${styles.input} ${
                    companyNameError && styles.error
                  }`}
                  name="companyName"
                  value={companyName}
                  onChange={this.onInputChange}
                />
                {companyNameError && (
                  <div className={styles.labelError}>
                    {data.label_error.text}
                  </div>
                )}
              </div>

              <div
                className={`${styles.inputContainer} ${
                  emailError && styles.error
                }`}
              >
                <div className={styles.label}>{data.label_email.text}*</div>
                <input
                  type="email"
                  className={`${styles.input} ${emailError && styles.error}`}
                  name="email"
                  value={email}
                  onChange={this.onInputChange}
                />
                {emailError && (
                  <div className={styles.labelError}>
                    {data.label_error_email.text}
                  </div>
                )}
              </div>

              <div className={styles.inputContainer}>
                <div className={styles.label}>{data.label_message.text}*</div>
                <textarea
                  className={`${styles.input} ${messageError && styles.error}`}
                  rows={6}
                  name="message"
                  value={message}
                  onChange={this.onInputChange}
                />
                {messageError && (
                  <div className={styles.labelError}>
                    {data.label_error.text}
                  </div>
                )}
              </div>

              <button className={styles.button}>
                {data.submit_button.text}
              </button>
            </form>
          </div>
        )}
      </div>
    );
  }

  private onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { name, companyName, email, message } = this.state;

    const nameError = name.length === 0 || !name.trim();
    const companyNameError = companyName.length === 0 || !companyName.trim();
    const emailError = !validateEmail(email);
    const messageError = message.length === 0 || !message.trim();

    if (nameError || companyNameError || emailError || messageError) {
      this.setState({
        nameError,
        companyNameError,
        emailError,
        messageError,
      });
    } else {
      axios
        .post(`${process.env.BASE_URL}/api/contactUs`, {
          name,
          companyName,
          email,
          message,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            this.setState({
              isMailSent: true,
              mailNotSent: false,
            });

            const event = {
              dataLayer: {
                event: "ga.contactForm",
                eventProps: {
                  category: "User Action",
                  action: "Submitted contact form",
                  userEmail: email,
                  userName: name,
                },
              },
            };

            TagManager.dataLayer(event);
          } else {
            this.mailNotSent();
          }
        })
        .catch(() => {
          this.mailNotSent();
        });
    }
  };

  private tryAgain = () => {
    this.setState({
      mailNotSent: false,
    });
  };

  private mailNotSent = () => {
    this.setState({
      isMailSent: false,
      mailNotSent: true,
    });
  };

  private onInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
      [`${name}Error`]: false,
    } as ComponentState);
  };
}
