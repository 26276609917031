import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "../components/Header";
import ContactUs from "../scenes/ContactUs";

import {
  IPrismicHeader,
  IPrismicContactUs,
  IPrismicFooter,
} from "../scenes/prismic.model";
import Footer from "../components/Footer";
import { FAVICON } from "../utils/constants";

interface IProps {
  data: {
    prismicHeader: {
      data: IPrismicHeader;
    };
    prismicContactUs: {
      data: IPrismicContactUs;
    };
    prismicFooter: {
      data: IPrismicFooter;
    };
  };
  pageContext: {
    locale: string;
    name: string;
  };
}

export default function HomePage(props: IProps) {
  return (
    <>
      <Helmet>
        <title>matchHR</title>
        <link rel="shortcut icon" href={FAVICON} type="image/x-icon" />
      </Helmet>
      <Header
        data={props.data.prismicHeader.data}
        pageContext={props.pageContext}
      />
      <ContactUs
        data={props.data.prismicContactUs.data}
        pageContext={props.pageContext}
      />
      <Footer data={props.data.prismicFooter.data} />
    </>
  );
}

export const pageQuery = graphql`
  query prismicContactUsQuery($locale: String!) {
    prismicHeader(lang: { eq: $locale }) {
      data {
        how_it_works_link {
          text
        }
        pricing_link {
          text
        }
        contact_us_link {
          text
        }
        about_us_link {
          text
        }
        login_link {
          text
        }
        signup_link {
          text
        }
        book_demo_link {
          text
        }
        get_started_link {
          text
        }
      }
    }
    prismicContactUs(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        description {
          text
        }
        label_name {
          text
        }
        label_company {
          text
        }
        label_email {
          text
        }
        label_message {
          text
        }
        label_error {
          text
        }
        label_error_email {
          text
        }
        submit_button {
          text
        }
        success_title {
          text
        }
        success_description {
          text
        }
        success_button {
          text
        }
        error_title {
          text
        }
        error_description {
          text
        }
        error_button {
          text
        }
      }
    }
    prismicFooter(lang: { eq: $locale }) {
      data {
        footer_heading {
          text
        }
        footer_label {
          text
        }
        get_started_button {
          text
        }
        copyright {
          text
        }
        contact_us_link {
          text
        }
        privacy_policy_link {
          text
        }
      }
    }
  }
`;
